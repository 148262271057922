import React from 'react';
import Layout from '../components/dfa-theme/layout';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ContentBlock from '../components/content-block';
import { Link as ScrollLink, Element } from 'react-scroll';
import FancyRadio from '../components/fancy-radio';
import { CONSTANTS } from '../utils/constants';
import BadgeComponent from '../components/badge-component';

import go2 from '../images/go2-logo.png';
import pimm from '../images/pimm-logo.png';
import asset2it from '../images/asset2it_logo.svg';
import hallpass from '../images/hallpass-logo.png';
import comms from '../images/comms.png';

import '../scss/pages/solutions.scss';

const Solutions = () => {
	const metaTags = {
		name: 'Solutions',
		description: 'Deerfield Solutions',
		keywords: 'Deerfield, Solutions',
	};


	return (
		<Layout meta={metaTags}>
			<div className='solutions-hero-container'>
				<ContentBlock
					outerBgColor={CONSTANTS.BROWN}
					className='solutions block'
				>
					<Grid>
						<Row>
							<Col xs={1} md={2}></Col>
							<Col xs={10} md={8} className='solutions-content title-block'>
								<div className='solutions-copy'>
									<p className='header-orange'>solutions</p>
									<h1>Smarter solutions for superior service.</h1>
									<p className='header-copy'>
										When you do well, we've done our job. And our job isn't
										finished until you've found success. That's what makes
										Deerfield your Agency of Brand
										<sup>&#174;</sup>. As an extension of your team, we are
										dedicated to doing whatever it takes to elevate your brand
										&ndash; one solution at a time.{' '}
									</p>
								</div>
							</Col>
							<Col xs={1} md={2}></Col>
						</Row>
					</Grid>
				</ContentBlock>
			</div>
			<div className='solutions-subnav'>
				<ContentBlock outerBgColor={CONSTANTS.WHITE} className='block'>
					<Grid>
						<Row>
							<Col xs={12} md={12} className='subnav'>
								<ScrollLink
									activeClass='active'
									className='fancy-radio'
									to='strategy'
									hashSpy={true}
									smooth={true}
									duration={500}
									offset={-75}
									rel='nofollow noopener'
									href='#strategy'
									aria-label='jump to Strategy and planning.'
								>
									<FancyRadio text={`Strategy and planning`} />
								</ScrollLink>
								<ScrollLink
									activeClass='active'
									className='fancy-radio'
									to='creative'
									hashSpy={true}
									smooth={true}
									duration={500}
									offset={-75}
									rel='nofollow noopener'
									href='#creative'
									aria-label='jump to Creative solutions.'
								>
									<FancyRadio text={`Creative solutions`} />
								</ScrollLink>
								<ScrollLink
									activeClass='active'
									className='fancy-radio'
									to='interactive'
									hashSpy={true}
									smooth={true}
									duration={500}
									offset={-75}
									rel='nofollow noopener'
									href='#interactive'
									aria-label='jump to Interactive.'
								>
									<FancyRadio text={`Interactive`} />
								</ScrollLink>
								<ScrollLink
									activeClass='active'
									className='fancy-radio'
									to='omnichannel'
									hashSpy={true}
									smooth={true}
									duration={500}
									offset={-75}
									rel='nofollow noopener'
									href='#omnichannel'
									aria-label='jump to Omnichannel marketing.'
								>
									<FancyRadio text={`Omnichannel marketing`} />
								</ScrollLink>
								<ScrollLink
									activeClass='active'
									className='fancy-radio'
									to='media'
									hashSpy={true}
									smooth={true}
									duration={500}
									offset={-75}
									rel='nofollow noopener'
									href='#media'
									aria-label='jump to Media planning.'
								>
									<FancyRadio text={`Media planning`} />
								</ScrollLink>
								<ScrollLink
									activeClass='active'
									className='fancy-radio'
									to='analytics'
									hashSpy={true}
									smooth={true}
									duration={500}
									offset={-75}
									rel='nofollow noopener'
									href='#analytics'
									aria-label='jump to Next-gen analytics.'
								>
									<FancyRadio text={`Next-gen analytics`} />
								</ScrollLink>
								<ScrollLink
									activeClass='active'
									className='fancy-radio'
									to='sales-enablement'
									hashSpy={true}
									smooth={true}
									duration={500}
									offset={-75}
									rel='nofollow noopener'
									href='#sales-enablement'
									aria-label='jump to Sales enablement.'
								>
									<FancyRadio text={`Sales enablement`} />
								</ScrollLink>
								<ScrollLink
									activeClass='active'
									className='fancy-radio'
									to='print-production'
									hashSpy={true}
									smooth={true}
									duration={500}
									offset={-75}
									rel='nofollow noopener'
									href='#print-production'
									aria-label='jump to Print management.'
								>
									<FancyRadio text={`Print management`} />
								</ScrollLink>
								<ScrollLink
									activeClass='active'
									className='fancy-radio'
									to='communications'
									hashSpy={true}
									smooth={true}
									duration={500}
									offset={-75}
									rel='nofollow noopener'
									href='#communications'
									aria-label='jump to Corporate communications management.'
								>
									<FancyRadio text={`Corporate communications`} />
								</ScrollLink>
							</Col>
						</Row>
					</Grid>
				</ContentBlock>
			</div>
			<div className='Proprietary-subnav'>
				<ContentBlock outerBgColor={CONSTANTS.WHITE} className='block'>
					<Grid>
						<Row>
							<Col xs={12}>
								<div className='subContainer'>
									<Grid>
										<Row>
											<Col xs={12}>
												<Element name='strategy' className='element'>
													<div className='icon icon-strategy'></div>
													<h2 className='blue'>Strategy and planning</h2>
												</Element>
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={4}>
												<h3>Journey mapping</h3>
												<span>
													Hone your audience targeting with profiles and
													journeys to identify prime opportunities of
													engagement.
												</span>
											</Col>
											<Col xs={12} md={4}>
												<h3>Go-to-market strategy and planning</h3>
												<span>
													Launch into market with proven strategies grounded in
													research and insights and fueled by rep detailing.
												</span>
											</Col>
											<Col xs={12} md={4}>
												<h3>Positioning and points of difference</h3>
												<span>
													From brands to products, we build from a strong,
													insight-driven foundation that leads to ownable
													truths.
												</span>
											</Col>
										</Row>
									</Grid>
								</div>

								<div className='subContainer'>
									<Grid>
										<Row>
											<Col xs={12}>
												<Element name='creative' className='element'>
													<div className='icon icon-creative'></div>
													<h2 className='blue'>Creative solutions</h2>
												</Element>
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={4}>
												<h3>Brand and visual identity</h3>
												<span>
													Your logo doesn't tell your entire brand narrative,
													but it's a start. We'll help you establish exactly
													what your brand identity should say, and then create
													an entire visual system that tells your story.
												</span>
											</Col>
											<Col xs={12} md={4}>
												<h3>Content strategy and creation</h3>
												<span>
													Content without strategy is just noise. That's why our
													strategists will determine a clear understanding of
													your business goals and audience needs to help our
													medical writers craft effective messaging across all
													stages of the content life cycle.
												</span>
											</Col>
											<Col xs={12} md={4}>
												<h3>Environmental design</h3>
												<span>
													From national and regional tradeshows and events to
													in-office interior design, our experienced team brings
													your brands to life for customers and internal
													stakeholders.
												</span>
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={4}>
												<h3>Video</h3>
												<span>
													From peer-to-peer to broadcast, we develop engaging
													visual stories that are perfectly suited for the busy
													schedules of today's healthcare professionals.
												</span>
											</Col>
											<Col xs={12} md={4}>
												<h3>Social content</h3>
												<span>
													Compelling messaging is a keystone of brand
													experience, especially in an environment where you
													have to entertain first. We develop and execute
													content with passion and purpose to increase
													engagement and brand affinity.
												</span>
											</Col>
											<Col xs={12} md={4}>
												<h3>Campaign development</h3>
												<span>
													You have a unique tale to tell. We'll make sure it's
													communicated in a way that is as memorable as you are
													– with validation from your target audience.
												</span>
											</Col>
										</Row>
										<Row>
											<Col xs={12}>
												<img
													src={go2}
													alt='Go2 Services Logo'
													className='go2-logo'
												/>
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={4}>
												<h3 className="product-icon-spacing">
													Go2<sup>®</sup> Services{' '}
												</h3>
												<span>
													Compliance is key in healthcare. Let us streamline
													your MLR process and label updates to get your
													industry-compliant materials in – or back in – the
													market faster and more affordably than ever before.
												</span>
											</Col>
											<Col xs={12} md={4}></Col>
											<Col xs={12} md={4}></Col>
										</Row>
									</Grid>
								</div>

								<div className='subContainer'>
									<Grid>
										<Row>
											<Col xs={12}>
												<Element name='interactive' className='element'>
													<div className='icon icon-interactive'></div>
													<h2 className='blue'>Interactive</h2>
												</Element>
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={4}>
												<h3>Interactive strategy</h3>
												<span>
													Technology evolves. Our certified digital team helps
													you keep up. Our research-driven interactive
													strategies synchronize your brand goals with the way
													users interact with the digital world.
												</span>
											</Col>
											<Col xs={12} md={4}>
												<h3>UX/UI design</h3>
												<span>
													Users are the center of our universe. We're dedicated
													to addressing their needs to ensure your brand
													delivers an engaging online experience while driving
													business performance.
												</span>
											</Col>
											<Col xs={12} md={4}>
												<h3>SEO and search marketing</h3>
												<span>
													Online search influences the user's journey. Optimize
													your brand's digital experience by uncovering your
													target audience's search behaviors to turn them into
													conversions.
												</span>
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={4}>
												<h3>
													Accessibility and
													<br />
													compliance{' '}
												</h3>
												<span>
													Solidify your brand's universal accessibility and
													ideal online experience via compliance with the
													Americans with Disabilities Act (ADA) and Web Content
													Accessibility Guidelines (WCAG).
												</span>
											</Col>
											<Col xs={12} md={4}>
												<h3>App creation </h3>
												<span>
													Remain at the forefront of digital marketing with
													custom-built applications across all platforms,
													including iOS, Android, and web. From prototype to
													launch, our team develops and executes first-class
													apps designed to engage your users.
												</span>
											</Col>
											<Col xs={12} md={4}></Col>
										</Row>
									</Grid>
								</div>

								<div className='subContainer'>
									<Grid>
										<Row>
											<Col xs={12}>
												<Element name='omnichannel' className='element'>
													<div className='icon icon-omnichannel'></div>
													<h2 className='blue'>Omnichannel marketing</h2>
												</Element>
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={4}>
												<h3>Multichannel approach</h3>
												<span>
													Establish a targeted surround sound experience for
													your brand's marketing strategy that simultaneously
													drives and tracks return on investment.
												</span>
											</Col>
											<Col xs={12} md={4}>
												<h3>Data-driven activation</h3>
												<span>
													Think like a customer. Leverage personal and
													nonpersonal omnichannel insights to create a more
													effective and convenient user experience.
												</span>
											</Col>
											<Col xs={12} md={4}>
												<h3>Marketing automation</h3>
												<span>
													Elevate your brand's customer relationship management
													(CRM) with a customized brand experience.
												</span>
											</Col>
											<Col xs={12} md={4}></Col>
										</Row>
									</Grid>
								</div>

								<div className='subContainer'>
									<Grid>
										<Row>
											<Col xs={12}>
												<Element name='media' className='element'>
													<div className='icon icon-media'></div>
													<h2 className='blue'>Media planning</h2>
												</Element>
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={4}>
												<h3>Media buying</h3>
												<span>
													Your brand's voice deserves to be heard. We'll make
													sure it reaches the right audience across all media
													platforms.
												</span>
											</Col>
											<Col xs={12} md={4}>
												<h3>Media tracking</h3>
												<span>
													Receive frequent assessments on the performance of
													your campaigns to identify ways to optimize and
													improve future brand communications.
												</span>
											</Col>
											<Col xs={12} md={4}></Col>
										</Row>
									</Grid>
								</div>

								<div className='subContainer'>
									<Grid>
										<Row>
											<Col xs={12}>
												<Element name='analytics' className='element'>
													<div className='icon icon-analytics'></div>
													<h2 className='blue'>Next-gen analytics</h2>
												</Element>
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={4}>
												<h3>Data analysis</h3>
												<span>
													Our analysts are willing to work independently or
													collaboratively to bring you accurate analytic
													insights that directly benefit your bottom line.
												</span>
											</Col>
											<Col xs={12} md={4}>
												<img
													src={pimm}
													alt='PIMM Logo'
													className='pimm-logo'
												/>
												<h3 className='rball-height product-icon-spacing'>
													Promotional Intelligence + Marketing Management
													<sup>®</sup>
												</h3>
												<span>
													Get targeted analytic reports that measure and track
													performance across each channel, segment, and
													specialty within your marketing campaign.
												</span>
											</Col>
											<Col xs={12} md={4}>
												<h3>Data optimization</h3>
												<span>
													We'll turn data into actionable insights. By
													interpreting each channel of your marketing campaign,
													we'll discover what's working, what's not, and adjust
													accordingly.
												</span>
											</Col>
										</Row>
									</Grid>
								</div>

								<div className='subContainer'>
									<Grid>
										<Row>
											<Col xs={12}>
												<Element name='sales-enablement' className='element'>
													<div className='icon icon-proprietary'></div>
													<h2 className='blue'>Sales enablement</h2>
												</Element>
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={4}>
												<img
													src={asset2it}
													alt='Asset2it Logo'
													className='asset2it-logo'
												/>
												<h3 className="product-icon-spacing">
													Asset2it<sup>®</sup>
												</h3>
												<span>
													Enhance your sales efforts by creating and
													distributing modular content to your clients in
													real time. With the integrated PDF view tracker,
													receive customer insights that can enhance future
													omnichannel strategy. Learn more at {' '}
													<a
														href="https://asset2it.com/"
														target="_blank"
														rel='noopener noreferrer'
														aria-label='See jobs at Deerfield.'>
														Asset2it.com
													</a>.
												</span>
											</Col>

											<Col xs={12} md={4}>
												<img
													src={hallpass}
													alt='Hallpass Logo'
													className='hallpass-logo'
												/>
												<h3 className="product-icon-spacing">
													HallPASS<sup>®</sup>
												</h3>
												<span>
													Whether requests for materials and samples or
													identified “hot leads,” our HALLPASS software will provide your sales force with the opportunity to provide white-glove service to your customers.
												</span>
											</Col>
										</Row>
									</Grid>
								</div>

								<div className='subContainer'>
									<Grid>
										<Row>
											<Col xs={12}>
												<Element name='print-production' className='element'>
													<div className='icon icon-print'></div>
													<h2 className='blue'>Print management</h2>
												</Element>
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={4}>
												<h3>Print strategy</h3>
												<span>
													Strategizing is a team effort. Our unbiased
													perspective will extend your brand's budget across
													account teams and other agency partners to maximize
													its value.
												</span>
											</Col>
											<Col xs={12} md={4}>
												<h3>Print procurement</h3>
												<span>
													Get ideal pricing from qualified printers. Our robust
													procurement software tool matches the offerings and
													services of our partners to meet your print needs.
												</span>
											</Col>
											<Col xs={12} md={4}>
												<h3>Print execution</h3>
												<span>
													On-time deliveries are nonnegotiable. That's why we
													work 24/7 to meet your deadlines for timely and
													successful launches with continued post-launch
													support.
												</span>
											</Col>
										</Row>
									</Grid>
								</div>
								<div className='subContainer'>
									<Grid>
										<Row>
											<Col xs={12}>
												<Element name='communications' className='element'>
													<img
														src={comms}
														alt='communications icon'
														className='comms-icon'
													/>
													<h2 className='blue'>Corporate and product communications</h2>
												</Element>
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={4}>
												<h3>Corporate communications strategy</h3>
												<span>
													We differentiate start-ups, early-stage organizations, and companies with marketed products. From scientific and data promotion to external and internal communications, we'll elevate your unique approach as an innovative industry leader.
												</span>
											</Col>
											<Col xs={12} md={4}>
												<h3>R&D, science communications</h3>
												<span>
													We bring compelling science to life at all stages of a compound's life cycle, grounded in the understanding of how we as humans connect to both rational and emotional storytelling.
												</span>
											</Col>
											<Col xs={12} md={4}>
												<h3>Product communications</h3>
												<span>
													Commercial success starts with creating authentic and empathetic product messaging and sharing it through proven launch and promotional strategies. These initiatives will help elevate innovation, integrity, and reputation of your brand every step of the way.
												</span>
											</Col>
										</Row>
									</Grid>
								</div>
							</Col>
						</Row>
					</Grid>
				</ContentBlock>
				<ContentBlock
					outerBgColor={CONSTANTS.BLUE}
					className='block block-blue'
				>
					<Grid>
						<Row>
							<Col xs={1}></Col>
							<Col xs={10} className='callout'>
								<h2>Impressive, right? Let us explain ourselves</h2>
								<p>
									Request a solutions presentation to learn how our full-service
									offerings can untap your brand's full marketing potential.
								</p>
								<a
									className='dfa-button-orange'
									href='/contact-us'
									aria-label='Meet the leadership at Deerfield.'
								>
									let's connect{' '}
								</a>
							</Col>
							<Col xs={1}></Col>
						</Row>
					</Grid>
				</ContentBlock>
			</div>
			<BadgeComponent isVisible={true} />
		</Layout>
	);
};
export default Solutions;
